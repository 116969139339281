@import "../../../index.scss";

.smallBox {
  position: relative;
  display: block;
  width: auto;
  height: 100%;
  margin: 2rem 0.4rem;
  .smallBox__img {
    z-index: 0;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .smallBox__data {
    color: black;
    z-index: 2;
    width: 100%;
    position: relative;
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      left: 0.5rem;
      bottom: 1rem;
    }
    h3 {
      min-width: 7rem;
      font-weight: 600;
    }
    h4 {
      min-width: 5rem;
      // top: 0.8rem;
      // right: 0.8rem;
      font-size: 0.8rem;
      font-weight: 500;
      padding: 0.2rem 0.4rem;
      text-transform: uppercase;
      // background: transparent;
      // background: green;
    }
  }
}
@media screen and (min-width: $medium) {
  .smallBox {
    gap: 1;
    // width: 240px;
    width: 100%;
    height: 200px;
    padding: 0;
    margin: 0.75rem 0;
    .smallBox__img {
      height: 160px;
    }
    .smallBox__data {
      h2 {
        font-size: 1.6rem;
      }
      h4 {
        margin: 0;
        padding: 0;
        // display: inline-block;
        color: white;
        color: red;
        background: white;
      }
    }
  }
}
