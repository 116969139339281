@import "../../index.scss";

.calendar-allMonths {
  border-radius: 0.5rem;
  margin: 1rem 0;
  width: 100%;
  tbody {
    tr {
      td {
        background: #e6e6e6;
        border-radius: 0.3rem;
        color: #fff;
        margin: 0.3rem;
        padding: 0.8rem 0;
        text-align: center;
        a {
          color: black;
          text-decoration: underline;
        }
      }
    }
  }
}
