@import "../../index.scss";

// .objectsList {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   width: 95%;
//   margin: 0 auto;
//   border: 1px solid green;
//   .objectsList__objects {
//     flex: 2;
//     padding: 0 0.5rem;
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//   }
// }

@media screen and (min-width: $medium) {
  .objectsList {
    flex-direction: row;
    flex-direction: column;
    width: 100%;
    .objectsList__objects {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      flex-direction: row;
      width: 100%;
      margin: 1.5rem 0;
      .objectsList__objects-left {
        border: 1px solid $grey1;
        margin-right: 2rem;
        h1 {
          text-align: center;
          margin: 0.5rem 0 1rem 0;
          font-size: 2rem;
          line-height: 2.6rem;
          text-transform: uppercase;
          // border-bottom: 4px solid $dark1;
        }
        grid-column: 1 / span 5;
        padding: 0 0.5rem;
        .objectsList__objects__obj {
          .obj-wrapper {
            display: grid;
            grid-template-columns: auto auto auto;
            gap: 0.75rem;
            padding: 0 0.5rem;
            // border: 1px solid red;
          }
        }
      }
      .objectsList__objects-right {
        grid-column: 6 / span 2;
        // padding: 0 1rem;
        // margin-right: 1rem;
        h1 {
          display: inline-block;
          border-bottom: 3px solid $dark1;
          font-weight: 600;
          margin: 0.25rem 0 0.75rem 0;
          padding-right: 0.5rem;
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
    }
  }
}
