@import "../../index.scss";

.mainNews {
  display: flex;
  .test11 {
    img {
      height: auto;
      width: 100%;
    }
    .test12 {
      margin: 0 0.5rem;
      // border-bottom: 1px solid $dark2;
      h2 {
        line-height: 1.6rem;
        font-size: 1.4rem;
        color: white;
        font-weight: normal;
      }
      h3 {
        // text-transform: capitalize;
        color: $dark3;
        font-size: 1rem;
        span {
          font-size: 0.8rem;
          text-transform: lowercase;
          color: rgb(168, 168, 168);
        }
      }
    }
  }
}
.test1 {
  .test11 {
    min-width: 300px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    img {
      // width: 35%;
      height: 100%;
      height: 100px;
      width: 120px;
      object-fit: cover;
      overflow: hidden;
      color: black;
      background: white;
      // border-radius: 8px;
    }
    .test12 {
      padding-left: 0.5rem;
      display: flex;
      flex-direction: column;
      width: 65%; //???????????????
      color: black;
      h2 {
        font-weight: 500;
        line-height: 1.2rem;
      }
      .test112 {
        padding-bottom: 0.1rem;
        margin-top: auto;
        // text-transform: capitalize;
        font-size: 0.9rem;
        font-weight: 600;
        color: $dark3;
        line-height: 1rem;
        span {
          display: none;
          font-size: 0.75rem;
          color: rgb(202, 202, 202);
          font-weight: 600;
          text-transform: lowercase;
        }
      }
    }
  }
}
.test1:last-child {
  border: none;
}
.test1.mainBox {
  display: flex;
  box-shadow: 0 0 13px 0 rgba(5, 30, 13, 0.1);
  border-bottom: none;
  .test11 {
    display: flex;
    flex-direction: column;
    padding: 0;
    img {
      width: 100%;
      height: 220px;
      object-fit: cover;
      overflow: hidden;
    }
    .test12 {
      display: flex;
      flex-direction: column;
      height: 80px;
      width: 100%;
      padding: 0.3rem 0.3rem;
      h2 {
        line-height: 1.4rem;
        font-size: 1.2rem;
        font-weight: 500;
      }
      .test112 {
        text-transform: capitalize;
        color: $dark1;
        font-size: 1.1rem;
        margin: 0;
        margin-top: auto;
        span {
          font-size: 1rem;
          color: rgb(168, 168, 168);
        }
      }
    }
  }
}
.test1.box1 {
  // border-bottom: 1px solid $dark2;
  .test11 {
    img {
      border-radius: 8px;
    }
    .test12 {
      h2 {
        color: white;
      }
    }
  }
}
.test1.box1:last-child {
  border: none;
}
.test1.box2 {
  display: flex;
  height: 120px;
  // border-bottom: 1px solid $grey4;
  .test11 {
    img {
      border-radius: 8px;
    }
    .test12 {
      h2 {
        color: black;
      }
      span {
        color: $grey4;
      }
    }
  }
}
.test1.box2:last-child {
  border-bottom: none;
}
.test1.box3 {
  height: 120px;
  display: flex;
  flex-direction: row;
  // border-bottom: 1px solid $barGreen4;
  .test11 {
    img {
      border-radius: 8px;
    }
    .test12 {
      h2 {
        color: white;
        color: black;
      }
      .test112 {
        padding-bottom: 0.1rem;
        color: $green1;
      }
    }
  }
}
.test1.box3:last-child {
  border: none;
}
.test1.box4 {
  display: flex;
  height: 120px;
  // border-bottom: 1px solid $grey4;
  .test11 {
    img {
      border-radius: 8px;
    }
  }
}
.test1.box4:last-child {
  border: none;
}
.test1.sideBox {
  display: flex;
  padding: 0;
  height: 120px;
  box-shadow: none;
  color: black;
  border-bottom: 1px solid $grey4;
  .test11 {
    img {
      border-radius: 10px;
      width: 35%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
    .test12 {
      h2 {
        color: black;
        line-height: 1.1rem;
        font-weight: 500;
      }
    }
  }
  h2 {
    color: black;
  }
}
.test1.sideBox:last-child {
  border: none;
}
@media screen and (min-width: $medium) {
  .mainNews {
    min-width: 600px;
    height: 390px;
    margin: 0.8rem 0.5rem 0.8rem 0.8rem;
    position: relative;
    border-radius: 10px;
    .test11 {
      height: 100%;
      position: relative;
      width: 100%;
      img {
        border-radius: 10px;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
      .test12 {
        border: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin: 0;
        padding: 1rem 0.6rem 0.3rem 0.6rem;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(
          to bottom,
          rgba(143, 143, 143, 0) 0%,
          $orange4 10%
        );
        h2 {
          color: rgb(255, 255, 255);
          font-size: 1.6rem;
          line-height: 1.9rem;
          text-shadow: none;
          font-weight: 400;
          cursor: pointer;
          &:hover {
            color: rgb(231, 231, 231);
          }
        }
        h3 {
          padding: 0;
          font-size: 1.2rem;
          color: $dark1;
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .test1 {
    color: white;
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0;
    border: none;
    // border-bottom: 1px solid rgb(180, 180, 180);
    .test11 {
      padding: 0;
      img {
        padding: 0;
        width: 150px;
        height: 100%;
      }
      .test12 {
        position: relative;
        h2 {
          font-size: 1.2rem;
          line-height: 1.4rem;
          text-shadow: none;
          margin: 0;
        }
        .test112 {
          font-size: 1.1rem;
          color: $dark1;
          span {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  .test1.mainBox {
    padding: 0;
    .test11 {
      img {
        // height: 260px;
      }
    }
  }
  .test1.sideBox {
    padding: 0.5rem 0;
    height: 120px;
    box-shadow: none;
    .test11 {
      img {
        border-radius: 5px;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
      .test12 {
        h2 {
          color: black;
          font-size: 1rem;
          line-height: 1.1rem;
          font-weight: 500;
        }
      }
    }
    h2 {
      color: black;
    }
  }
  .test1.box1 {
    // border-bottom: 1px solid $grey4;
    .test11 {
      .test12 {
        h2 {
          font-size: 1.15rem;
        }
      }
    }
  }
  .test1.box2 {
    height: 160px;
    width: 100%;
    .test11 {
      width: 100%;
      img {
        padding: 0;
        width: 280px;
        height: auto; //????
        border-radius: 8px;
      }
      .test12 {
        width: 100%;
        .test112 {
          margin-bottom: auto;
          position: absolute;
          bottom: 0;
          text-transform: capitalize;
          color: $dark1;
          font-size: 1.1rem;
          letter-spacing: 0.05rem;
          text-transform: capitalize;
          font-weight: bold;
          font-size: 1.2rem;
          span {
            text-transform: lowercase;
          }
        }
      }
    }
  }
  .test1.box2.news2 {
    height: 180px;
    .test11 {
      img {
        width: 320px;
        height: 160px;
      }
      .test12 {
        h2 {
          color: white;
        }
      }
    }
  }
  .test1.box2.news3 {
    min-height: 200px;
    .test11 {
      min-height: 160px;
      img {
        min-width: 240px;
      }
    }
  }
  .test1.box3 {
    background: white;
    height: 320px;
    padding: 0;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 2px 3px rgb(202, 202, 202);
    .test11 {
      display: flex;
      flex-direction: column;
      height: 100%;
      img {
        border-radius: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 100%;
      }
      .test12 {
        padding: 0.3rem 0.5rem;
        width: 100%;
        height: 80px;
        h2 {
          color: black;
          line-height: 1.5rem;
          font-size: 1.2rem;
        }
        .test112 {
          color: $green1;
        }
      }
    }
  }
  .test1.box4 {
    box-shadow: 0 0 13px 0 rgba(5, 30, 13, 0.1);
    border: none;
    border-radius: 10px;
    border-radius: 0;
    height: 140px;
    padding: 0;
    .test11 {
      img {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        width: 50%;
        border-radius: 0;
      }
      .test12 {
        padding: 0.2rem 0.4rem;
        h2 {
          font-size: 1.1rem;
        }
      }
    }
  }
  .test1.box4.small {
    height: 120px;
    margin-bottom: 0.5rem;
    .test11 {
      .test12 {
        .test112 {
          display: none;
        }
      }
    }
  }
}
