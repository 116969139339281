@import "../../index.scss";

.ikone {
  // border: 1px solid red;
}

@media screen and (min-width: $medium) {
  .ikone {
    .ikone-wrapper {
      display: flex;
      .ikone-text {
        width: calc(100% - 340px);

        p {
          font-size: 1.2rem;
        }
        .ikone-img {
          text-align: center;

          padding: 1rem 2rem;
          .mainContent-img {
            //
            position: relative;
            img {
              width: 100%;
              height: auto;
            }
            span {
            }
          }
          .mainContent-img_source {
            background: #1e1e1ecc;
            border-radius: 0.2rem;
            bottom: 0.5rem;
            left: 0.5rem;
            padding: 0.1rem 0.5rem;
            position: absolute;
            span {
              color: #ffffffd9;
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}
