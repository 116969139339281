@import "./index.scss";
.app {
  position: relative;
}
@media screen and (min-width: $medium) {
  .app {
    width: 100%;
    margin: 0 auto;
    .app-wrapper {
      margin: 0 auto;
    }
  }
}
@media screen and (min-width: $extraLarge) {
  .app {
    width: 100%;
    margin: 0 auto;
    .app-wrapper {
      width: 1280px;
      margin: 0 auto;
    }
  }
}
