@import "../../index.scss";

.home__box__3 {
  margin: 0.8rem 0.2rem;
  padding: 0 0.6rem;
  background: rgb(246, 246, 246);
  border: 3px solid rgb(239, 239, 239);
  border-radius: 10px;
  .home_3_title {
    display: flex;
    justify-content: space-between;
    color: white;
    a {
      text-decoration: none;
      h2 {
        font-size: 1.4rem;
        color: $barGreen4;
      }
    }

    span {
      font-size: 1.4rem;
    }
  }
}

@media screen and (min-width: $medium) {
  .home__box__3 {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 0.8 auto;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    border: 2px solid rgb(219, 219, 219);
    .home_3_title {
      display: flex;
      justify-content: space-between;
      padding: 0 0.5rem 0.5rem 0.5rem;
      a {
        text-decoration: none;
        h2 {
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
      span {
        font-size: 2rem;
      }
    }
    .box3 {
      .box3__left {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.5rem;
      }
    }
  }
}
