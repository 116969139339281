@import "../../index.scss";

.pagination {
  grid-column: span 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  div {
    text-align: center;
    border-radius: 50%;
    background: white;
    border: 1px solid $orange1;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    color: $orange1;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    margin: 0 0.5rem;
  }
  .open {
    background: $orange1;
    color: white;
  }
}
@media screen and (min-width: $medium) {
  .pagination {
    grid-column: span 2;
    padding: 2.5rem 0 1.5rem 0;
  }
}
@media screen and (min-width: $large) {
  .pagination {
    grid-column: span 3;
  }
}
