@import "../../index.scss";

.kalendar {
  .kalendar-wrapper {
    padding: 0 0.4rem;
  }
}

@media screen and (min-width: $medium) {
  .kalendar {
    padding: 2rem 0;
    width: 100%;
    .kalendar-wrapper {
      display: flex;
      .kalendar-left {
        display: flex;
        flex-direction: column;
        width: calc(100% - 340px);
        margin-right: 1rem;
        .zadusnice {
          height: 600px;
        }
      }
      .kalendar-right {
        text-align: center;
        width: 340px;
      }
    }
  }
}
