@import "../../index.scss";

.zadusnice {
  width: 100%;

  table {
    width: 100%;
    border-bottom: 1px solid grey;
    border-collapse: collapse;
    thead {
      display: table-header-group;
      vertical-align: middle;
      unicode-bidi: isolate;
    }
    tbody {
      tr {
        td {
          padding: 0.4rem;
          border-bottom: 1px solid rgb(197, 197, 197);
        }
      }
    }
    thead tr th {
      background: #f9f1c8;
      border-bottom: 2px solid #8a6629;
      font-size: 1.2rem;
      height: 2rem;
      line-height: 2rem;
      padding-left: 0.3rem;
    }
  }
}
