@import "../../index.scss";

.bars {
  min-width: 300px;
  height: 50px;
  min-height: 35px;
  z-index: +999;
  .bars__logo {
    font-size: 2.2rem;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    b {
      font-weight: 700;
      color: $dark1;
      font-size: 2.2rem;
    }
  }
}
@media screen and (min-width: $medium) {
  .bars {
    display: flex;
    width: 100%;
    height: 5rem;
    margin: 0 auto;
    padding: 0 1rem;
    border-bottom: 4px double black;
    .bars__logo {
      font-size: 3.5rem;
      b {
        font-size: 3.5rem;
      }
    }
  }
}
@media screen and (min-width: $extraLarge) {
  .bars {
    .bars__links {
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
