@import "../../../index.scss";

.topBar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  // background: $green4;
  // background: $dark4;
  // background: $orange3;
  .topBar__link {
    display: none;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-align: left;
    text-decoration: none;
    // color: white;
    color: black;
    text-decoration: none;
    text-shadow: 0px 1px 3px grey;
    i {
      // color: white;
      color: black;
      font-size: 1em;
      margin-right: 0.2rem;
    }
  }
}

@media screen and (min-width: $medium) {
  .topBar {
    flex-direction: row;
    height: auto;
    justify-content: flex-end;
    .topBar__link {
      display: inline;
      font-size: 1.2rem;
      line-height: 1.3rem;
      font-weight: 500;
      padding: 0.3rem 0 0.3rem 0.8rem;
      text-shadow: none;
      i {
        margin-right: 0.8rem;
      }
    }
  }
}
