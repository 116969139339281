@import "../../index";

button {
  display: inline;
  color: white;
  background: transparent;
  outline: none;
  cursor: pointer;
  transition: all 0.1s linear;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
}
